<template>
    <div class="agreement">
        <div class="agreement-container">
            <div class="agreement-header">
                <h1>加入须知</h1>
            </div>
            <div class="agreement-content">
                <div class="agreement-content-title">第一章 总则</div>
                <div class="agreement-content-part"><span>第一条</span>本协会的名称：上饶市平安义警协会，英文译名：Shangrao Vigilantes。</div>
                <div class="agreement-content-part">
                    <span>第二条</span>本协会的性质：为弘扬“一座城、一条心、一起拼、一定赢”精神，促进上饶市域社会治理及社会生活环境稳定发展，提升上饶城市品格，由单位及个人自愿组成、依法设立的非营利性社会组织。
                </div>
                <div class="agreement-content-part">
                    <span>第三条</span>本协会宗旨：坚持以习近平新时代中国特色社会主义思想为指导，遵守宪法、法律、法规和国家政策，普及社会主义核心价值观和志愿理念，弘扬警察精神，践行尚法崇德、守正创安，发动广大会员积极参与平安建设、市域社会治理和文明创建等工作，为建设平安和谐、美丽幸福的上饶贡献力量，用心用情，用高质量的服务让全市人民过上幸福安康的好日子。
                </div>
                <div class="agreement-content-part">
                    <span>第四条</span>本协会坚持党的领导，根据中国共产党《章程》的规定，设立党的组织，开展党的活动，创新党建工作，并为党组织活动提供一切保障；坚持党的政治引领作用、团结凝聚广大会员，把上饶平安义警办成全市标杆，全省一流，全国知名的社会组织。
                </div>
                <div class="agreement-content-part"><span>第五条</span>本协会接受上饶市公安局业务指导和民政局监督管理，党建领导机关为上饶市公安局党委、上饶市非公组织党委。
                </div>
                <div class="agreement-content-part">
                    <span>第六条</span>本协会在各县（市、区），三清山风景名胜区、上饶经济技术开发区管委会（以下筒称“12+2”）设立“上饶市平安义警协会分会”；在各乡镇、街道设立“义警中队”；各辖区义警队伍组成原则上以“12+2”公安局（分局）、派出所辖区为建制，按照平安义警所在辖区，结合个人意向、职业特点等因素分组建队，原则上派出所负责人任该辖区义警中队指导员，各义警分会选举产生各义警中队中队长，中队下设若干义警小分队，以辖区网格进行划分管辖区域，每个小分队由派出所民警、网格员、专职义警、义警志愿者组成，义警中队任命各小分队队长，派出所民警、网格员兼任小分队指导员，并建档上线。
                </div>
                <div class="agreement-content-part"><span>第七条</span>本协会成立理事会，由会员代表大会选举产生，负责日常工作开展。</div>
                <div class="agreement-content-part"><span>第八条</span>本协会支持设立工、青、妇组织。</div>
                <div class="agreement-content-part"><span>第九条</span>本协会会址：上饶市广信区广信大道118号。</div>

                <div class="agreement-content-title">第二章 职责范围</div>
                <div class="agreement-content-part"><span>第十条</span>本协会的职责：</div>
                <div class="agreement-content-part">
                    (一)本协会以团结有志于维护社会治安与和谐稳定的民众为己任，积极探索研究上饶平安建设、市域社会治理和文明城市创建，支持全市社会治理、安全防范、矛盾纠纷化解和法制宣传等工作。指导、支持上饶市“12+2”平安义警协会工作。
                </div>
                <div class="agreement-content-part">(二)协助公安机关开展治安巡逻防范、群防群治等辅助性警务活动，预防、减少违法犯罪行为的发生，维护社会治安秩序。</div>
                <div class="agreement-content-part">(三)指导、监督上饶市“12+2”平安义警协会各项工作的开展。</div>
                <div class="agreement-content-part">
                    (四)配合政法机关开展法制宣传、普及法律法规知识、安全知识、传播平安文化，树立先进典型，弘扬正能量，不断提高公民的法律意识，打造“平安上饶”品牌。</div>
                <div class="agreement-content-part">
                    (五)弘扬警察精神，营造崇法向善的社会氛围、开展纠纷调解、文明劝导、社区关怀等志愿活动，努力创建平安和谐社区、了解、反映社情民意，促进基层党群关系、政群关系、警民关系和谐发展。</div>
                <div class="agreement-content-part">(六)它山之石可以攻玉。“走出去”、“请进来”，多渠道交流，多方式创新，倍增服务品质。</div>
                <div class="agreement-content-part">(七)组织、发动社会公众参与平安建设、文明城市创建、参政议政，积极完成党委政府交办的有关工作任务，开展与本协会宗旨有关的其他活动。
                </div>

                <div class="agreement-content-title">第三章 会员</div>
                <div class="agreement-content-part"><span>第十一条</span>本协会的会员为单位会员和个人会员。</div>
                <div class="agreement-content-part"><span>第十二条</span>申请加入本协会的会员，必须具备下列条件：</div>
                <div class="agreement-content-part">(一)有加入本协会的意愿；</div>
                <div class="agreement-content-part">(二)拥护本协会的章程；</div>
                <div class="agreement-content-part">(三)热心于公益事业；</div>
                <div class="agreement-content-part">(四)具有独立承担民事责任能力。</div>

                <div class="agreement-content-part"><span>第十三条</span>会员入会的程序是：</div>
                <div class="agreement-content-part">(一)提交入会申请书；</div>
                <div class="agreement-content-part">(二)由协会办事机关审核通过；</div>
                <div class="agreement-content-part">(三)由理事会或理事会授权的机构发给会员证。</div>

                <div class="agreement-content-part"><span>第十四条</span>会员享有下列权利：</div>
                <div class="agreement-content-part">(一)本协会的选举权、被选举权和表决权；</div>
                <div class="agreement-content-part">(二)参加本协会组织的有关活动；</div>
                <div class="agreement-content-part">(三)获得本协会服务的优先权；</div>
                <div class="agreement-content-part">(四)对本协会工作的知情权、建议权和监督权；</div>
                <div class="agreement-content-part">(五)入会自愿、退会自由；</div>

                <div class="agreement-content-part"><span>第十五条</span>会员履行下列义务：</div>
                <div class="agreement-content-part">(一)遵守本协会章程；</div>
                <div class="agreement-content-part">(二)维护本协会合法权益；</div>
                <div class="agreement-content-part">(三)积极参加本协会组织的活动，认真完成本协会安排的工作；</div>
                <div class="agreement-content-part">(三)积极参加本协会组织的活动，认真完成本协会安排的工作；</div>
                <div class="agreement-content-part">(五)按规定缴纳会费（会费缴纳标准另文规定）。</div>

                <div class="agreement-content-part">
                    <span>第十六条</span>会员退会应书面通知本协会，并交回会员证和徽章。会员不遵守本会规章制度或6个月以上不参加本协会活动的，视为自动退会。
                </div>
                <div class="agreement-content-part"><span>第十七条</span>会员如有严重违反本章程的行为，经理事会表决通过，予以除名。</div>
                <div class="agreement-content-part">(一)受到刑事处罚的；</div>
                <div class="agreement-content-part">(二)受到公安机关2次(含)警告以上行政处罚的；</div>
                <div class="agreement-content-part">(三)违反本协会纪律及规章制度，一年内经3次(含)以上警告拒不改正的；</div>
                <div class="agreement-content-part">(四)无故不参加本协会安排的活动2次(含)以上，且未向理事会作情况说明的；</div>
                <div class="agreement-content-part">(五)有其他严重违反章程或严重损害本协会形象情形的。</div>

                <div class="agreement-content-title">第四章 组织机构和负责人产生、罢免</div>

                <div class="agreement-content-part"><span>第十八条</span>本协会的最高权力机构是会员代表大会，会员代表大会的职权是：</div>
                <div class="agreement-content-part">(一)制定和修改章程；</div>
                <div class="agreement-content-part">(二)选举和罢免理事；</div>
                <div class="agreement-content-part">(三)审议理事会的工作报告和财务报告；</div>
                <div class="agreement-content-part">(四)决定终止事宜；</div>
                <div class="agreement-content-part">(五)审议决定本协会其他重大事宜。</div>
                <div class="agreement-content-part"><span>第十九条</span>会员代表大会须有2/3以上的会员代表出席方能召开，其决议须经到会会员代表半数以上表决通过方能生效。
                </div>
                <div class="agreement-content-part">
                    <span>第二十条</span>会员代表大会每届5年。因特殊情况需提前或延期换届的，须由理事会表决通过，报行政主管单位审查并经社团登记管理机关批准同意。但延期换届最长不超过1年。
                </div>
                <div class="agreement-content-part"><span>第二十一条</span>理事会是会员代表大会的执行机构，在会议期间领导本协会开展日常工作，对会员代表大会负责。</div>
                <div class="agreement-content-part"><span>第二十二条</span>理事会的职权是：</div>
                <div class="agreement-content-part">(一)筹备召开会员代表大会；</div>
                <div class="agreement-content-part">(二)执行会员代表大会的决议；</div>
                <div class="agreement-content-part">(三)向会员代表大会报告工作；</div>
                <div class="agreement-content-part">(四)听取和审议理事会的工作报告；</div>
                <div class="agreement-content-part">(五)推举名誉会长；</div>
                <div class="agreement-content-part">(六)选举和罢免会长、副会长、秘书长和常务理事；</div>
                <div class="agreement-content-part">(七)决定设立办事机构、分支机构、代表机构和实体机构</div>
                <div class="agreement-content-part">(八)制定内部管理制度；</div>
                <div class="agreement-content-part">(九)决定其他重大事项。</div>
                <div class="agreement-content-part"><span>第二十三条</span>理事会须有2/3以上理事出席方能召开，其决议须经到会理事2/3以上表决通过方能生效。</div>
                <div class="agreement-content-part"><span>第二十四条</span>理事会每年至少召开2次会议；情况特殊的，也可采用通讯形式召开。</div>
                <div class="agreement-content-part"><span>第二十五条</span>本协会设名誉会长、名誉副会长若干人:会长1人，常务副会长、副会长若干人，秘书长1人，副秘书长若干人，常务理事若干人。</div>
                <div class="agreement-content-part"><span>第二十六条</span>理事会须有2/3以上理事出席方能召开，其决议须经到会理事2/3以上表决通过方能生效。</div>
                <div class="agreement-content-part"><span>第二十七条</span>理事会至少半年召开1次会议；情况特殊的也可采用通讯形式召开。</div>
                <div class="agreement-content-part"><span>第二十八条</span>本协会的会长、常务副会长、副会长、秘书长必须具备下列条件：</div>
                <div class="agreement-content-part">（一）拥护党的路线、方针、政策，政治素质好；</div>
                <div class="agreement-content-part">（二）具有比较丰富的社会工作经验和一定的组织管理能力，热心本协会的工作；</div>
                <div class="agreement-content-part">（三）理事长（会长）、副理事长（副会长）、秘书长最高任职年龄不超过70周岁，秘书长为专职；</div>
                <div class="agreement-content-part">（四）身体健康，能坚持正常工作；</div>
                <div class="agreement-content-part">（五）未受过剥夺政治权利及刑事处罚的；</div>
                <div class="agreement-content-part">（六）具有完全民事行为能力；</div>
                <div class="agreement-content-part">（七）获得主管部门的推荐提名。</div>
                <div class="agreement-content-part"><span>第二十九条</span>本协会会长、常务副会长、副会长、秘书长如超过最高任职年龄的，须经理事会表决通过，报业务主管单位审查并社团登记管理机关批准同意后，方可任职。</div>
                <div class="agreement-content-part"><span>第三十条</span>本协会会长、常务副会长、副会长、秘书长任期5年。〔理事长(会长)、副理事长(副会长)、秘书长任期最长不得超过两届〕因特殊情况需延长任期的，须经会员代表大会2/3以上会员代表)表决通过，报业务主管单位审查并经社团登记管理机关批准同意后方可任职。</div>
                <div class="agreement-content-part"><span>第三十一条</span>本协会会长为本协会法定代表人。</div>
                <div class="agreement-content-part"><span>第三十二条</span>本协会会长行使下列职权；</div>
                <div class="agreement-content-part">（一）主持本协会的全面工作；。</div>
                <div class="agreement-content-part">（二）召集和主持理事会、会长办公会；</div>
                <div class="agreement-content-part">（三）代表本协会签署有关重要文件、开展外部交流活动；</div>
                <div class="agreement-content-part">（四）审核、批准协会财务开支；</div>
                <div class="agreement-content-part">（五）管理、使用协会印鉴；</div>
                <div class="agreement-content-part">（六）提名副秘书长及各分支机构、代表机构；和实体机构主要负责人，提交理事会议决定；</div>
                <div class="agreement-content-part">（七）检查、督促会员代表大会、理事会议、决定、决议的落实。</div>
                <div class="agreement-content-part">（八）决定本协会相关工作人员的聘用；</div>
                <div class="agreement-content-part">（九）维护协会和会员的合法权益；</div>
                <div class="agreement-content-part">（十）理事会授权的其他工作。</div>
                <div class="agreement-content-part"><span>第三十三条</span>本协会常务副会长行使以下职权：</div>
                <div class="agreement-content-part">（一）本协会常务副会长全面协助会长工作</div>
                <div class="agreement-content-part">（二）在会长不能履职或其授权下代其履行会长职责；</div>
                <div class="agreement-content-part">（三）向会长推荐副秘书长以及各办事机构、分支机构、代表机构和实体机构主要负责人，交常务理事会决定；</div>
                <div class="agreement-content-part">（四）向会长建议本协会相关工作人员的聘用。</div>
                <div class="agreement-content-part">（五）检查、督促会员代表大会、理事会、常务理事会、会长办公会各项决议的落实情况；</div>
                <div class="agreement-content-part">（六）维护协会和会员的合法权益；</div>
                <div class="agreement-content-part">（七）理事会、常务理事会和会长授权的其他工作；</div>
                <div class="agreement-content-part"><span>第三十四条</span>本协会秘书长行使下列职权：</div>
                <div class="agreement-content-part">（一）主持办事机构开展日常工作，组织实施年度工作计划；</div>
                <div class="agreement-content-part">（二）协调各分支机构、代表机构、实体机构开展工作；</div>
                <div class="agreement-content-part">（三）提名副秘书长以及各办事机构、分支机构、代表机构和实体机构主要负责人，交理事会或常务理事会决定；</div>
                <div class="agreement-content-part"> (四)决定办事机构、代表机构、实体机构专职工作人员的聘用；</div>
                <div class="agreement-content-part">（五）会员入会、退会的审查和审核工作；</div>
                <div class="agreement-content-part">（六）协会行政印鉴管理使用工作；</div>
                <div class="agreement-content-part">（七）协会宣传报道、形象展示工作；</div>
                <div class="agreement-content-part">（八）理事会、常务理事会、会长办公室和会长、常务副会长授权的其他工作。</div>
                
                    <div class="agreement-content-title">第五章  资产管理、使用原则</div>
                         
                <div class="agreement-content-part"><span>第三十五条</span>本协会经费来源：</div>
                <div class="agreement-content-part">（一）会费</div>
                <div class="agreement-content-part">（二）捐赠；</div>
                <div class="agreement-content-part">（三）政府资助；</div>
                <div class="agreement-content-part">（四）其他合法收入；</div>
                <div class="agreement-content-part"><span>第三十六条</span>按照国家有关规定收取会费。</div>
                <div class="agreement-content-part"><span>第三十七条</span>本协会经费必须用于本章程规定的业务范围和事业的发展，不得在会员中分配。</div>
                <div class="agreement-content-part"><span>第三十八条</span>本协会建立严格的财务管理制度，保证会计资料合法、真实、准确、完整。</div>
                <div class="agreement-content-part"><span>第三十九条</span>本协会配备具有专业资格的会计人员。会计不得兼任出纳。会计人员必须进行会计核算，实行会计监督。会计人员调动工作或离职时，必须与接管人员办清交接手续。</div>
                <div class="agreement-content-part"><span>第四十条</span>本协会的资产管理必须执行国家规定的财务管理制度，接受会员代表大会和财政部门的监督。资产来源属于国家拨款或者社会捐赠、资助的，必须接受审计机关的监督，并将有关情况以适当方式向社会公布。</div>
                <div class="agreement-content-part"><span>第四十一条</span>本协会换届或更换法定代表人之前必须接受社团登记管理机关和业务主管单位组织的财务审计。</div>
                <div class="agreement-content-part"><span>第四十二条</span>本协会的资产，任何单位、个人不得侵占、私分和挪用。</div>
                <div class="agreement-content-part"><span>第四十三条</span>本协会专职工作人员的工资和保险、福利待遇，参照国家对事业单位的有关规定执行。</div>
                
                    <div class="agreement-content-title">第六章  章程的修改程序</div>
                    
                <div class="agreement-content-part"><span>第四十四条</span>对本协会章程的修改，须经理事会表决通过后报会员代表大会审议通过。</div>
                <div class="agreement-content-part"><span>第四十五条</span>本协会修改的章程，须在会员代表大会通过后15日内，经业务主管单位审查同意，并报社团登记管理机关核准后生效。</div>
                
                    <div class="agreement-content-title">第七章  终止程序及终止后的财产处理</div>
                
                <div class="agreement-content-part"><span>第四十六条</span>本协会完成宗旨或自行解散或由于分立、合并等原因需要注销的，由理事会或常务理事会提出终止动议。</div>
                <div class="agreement-content-part"><span>第四十七条</span>本协会终止动议须经会员代表大会表决通过，并报业务主管单位审查同意。</div>
                <div class="agreement-content-part"><span>第四十八条</span>本协会终止前，须在业务主管单位及有关机关指导下成立清算组织，清理债权债务，处理善后事宜。清算期间，不开展清算以外的活动。</div>
                <div class="agreement-content-part"><span>第四十九条</span>本协会经社团登记管理机关办理注销登记手续后即为终止。</div>
                <div class="agreement-content-part"><span>第五十条</span>本协会终止后的剩余财产，在业务主管单位和社团登记管理机关的监督下，按照国家有关规定，用于发展与本协会宗旨相关的事业。</div>
                
                <div class="agreement-content-title">第八章  附 则</div>
                   
                <div class="agreement-content-part"><span>第五十一条</span>本章程经2022年X月X日会员代表大会表决通过。</div>
                <div class="agreement-content-part"><span>第五十二条</span>本章程的解释权属本协会的理事会。</div>
                <div class="agreement-content-part"><span>第五十三条</span>本章程自社团登记管理机关核准之日起生效。</div>
                
                <div class="agreement-content-part agreement-content-part-right">上饶市平安义警协会</div>
                <div class="agreement-content-part agreement-content-part-right">2022年X月X日</div>
                    
            </div>
            <div class="agreement-footer">
                <van-button type="primary" block color="#3377FF" @click="$router.back()">我已阅读并同意</van-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RegisterNotice',
    created() {

    }
}
</script>

<style lang="less" scoped>
.agreement {
	background-color: #fff;
    .agreement-container {
        padding: 0 10px;

        .agreement-header {
            padding: 20px 0 15px;
            border-bottom: 1px solid #eee;

            h1 {
                text-align: center;
                font-size: 20px;
                color: #333;
                line-height: 30px;
                margin: 0;
                font-weight: normal;
            }
        }

        .agreement-content {
            height: calc(100vh - 86px - 66px);
            box-sizing: border-box;
            overflow-y: scroll;

            .agreement-content-title {
                font-size: 18px;
                font-weight: bold;
                color: #333;
                text-align: center;
                padding-top: 15px;
                margin-bottom: 15px;
            }

            .agreement-content-part {
                font-size: 16px;
                color: #666;
                text-indent: 2em;
                margin-bottom: 10px;
                line-height: 1.8;

                &.agreement-content-part-right {
                    text-align: right;
                    text-indent: 0;
                }
            }
        }

        .agreement-footer {
            padding: 20px 0;

            .van-button {
                font-size: 16px;
                border-radius: 8px;
            }
        }
    }

}
</style>
